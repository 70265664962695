import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Design, Donation } from '@domains';
import { environment } from '@driver/../environments/environment';
import { AuthenticationService } from '@rspl-auth';
import { DesignService, Destructible, ResponsiveService } from '@rspl-ui';
import { VersionCheckService } from '@rspl-version';
import * as moment from 'moment';
import { take, takeUntil } from 'rxjs/operators';
import { DownloadAppWarningDialogComponent } from './driver/components/download-app-warning-dialog/download-app-warning-dialog.component';
import { EtaDonationDialogComponent } from './driver/components/eta-donation-dialog/eta-donation-dialog.component';
import { MainService } from './services/main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends Destructible implements OnInit {
  @ViewChild('Location') locationTemplate!: TemplateRef<any>;
  versionValid?: boolean;
  isLoggedIn = false;
  isDemo = environment.isDemoBuild;
  isStaging = environment.isStagingBuild;
  isDev = environment.isDevBuild;
  zendesk = environment.urls.zendesk;
  locationState?: 'granted' | 'prompt' | 'denied';
  app = environment.app;
  locationDialog?: MatDialogRef<any>;
  isReady = false;
  isRefreshing = false;
  etaDonations: Donation[] = [];
  isDownloadDialogOpen = false;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private versionCheck: VersionCheckService,
    private designService: DesignService,
    private dialog: MatDialog,
    private service: MainService,
    private responsiveService: ResponsiveService
  ) {
    super();
    moment.updateLocale('en', {
      week: {
        dow: 1,
        doy: 4,
      },
    });
    iconRegistry.addSvgIcon(
      'truck-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck-light.svg')
    );
    iconRegistry.addSvgIcon(
      'parachute-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/parachute-box-light.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'charities-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/warehouse-alt-light.svg'
      )
    );
  }

  ngOnInit(): void {
    this.versionCheck
      .initVersionCheck()
      .pipe(take(1))
      .subscribe(() => {
        this.versionValid = true;
      });
    this.designService.setDesign(Design.DESIGN_1);
    this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      this.isReady = true;
      if (this.isLoggedIn && !this.isDownloadDialogOpen) {
        this.showDownloadAppWarning();
      }
    });

    this.location();

    this.service.donations$
      .pipe(takeUntil(this.destroy$))
      .subscribe((donations) => {
        this.etaDonations = donations?.filter(
          (d) => (d as Donation).requestEta
        );
        if (this.etaDonations.length > 0) {
          this.editEta(0);
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    const w = event.target as Window;
    this.responsiveService.setWindowSize(w.innerWidth);
  }

  logout(): void {
    this.authService.logout();
  }

  location() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      this.setLocation(result.state);
      result.addEventListener('change', () => {
        this.setLocation(result.state);
      });
    });
  }

  setLocation(state?: 'granted' | 'prompt' | 'denied') {
    this.locationState = state;
    if (this.locationState !== 'granted') {
      // this.locationDialog = this.dialog.open(this.locationTemplate);
    }
  }

  editEta(i: number): void {
    const donation = this.etaDonations[i];
    if (!donation) {
      this.etaDonations = [];
      return;
    }
    this.dialog
      .open(EtaDonationDialogComponent, {
        width: '320px',
        disableClose: true,
        data: { donation, canCancel: false },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.editEta(i + 1);
        }
      });
  }

  showDownloadAppWarning(): void {
    this.isDownloadDialogOpen = true;
    this.dialog
      .open(DownloadAppWarningDialogComponent, {
        width: '640px',
        maxWidth: '100%',
        disableClose: false,
        data: { canCancel: false },
      })
      .afterClosed()
      .subscribe((res) => {
        this.isDownloadDialogOpen = false;
      });
  }
}
